exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-ai-equity-js": () => import("./../../../src/pages/ai/equity.js" /* webpackChunkName: "component---src-pages-ai-equity-js" */),
  "component---src-pages-angel-tax-calculator-index-js": () => import("./../../../src/pages/angel/tax-calculator/index.js" /* webpackChunkName: "component---src-pages-angel-tax-calculator-index-js" */),
  "component---src-pages-business-owners-index-js": () => import("./../../../src/pages/business-owners/index.js" /* webpackChunkName: "component---src-pages-business-owners-index-js" */),
  "component---src-pages-docs-api-index-js": () => import("./../../../src/pages/docs/api/index.js" /* webpackChunkName: "component---src-pages-docs-api-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nerdwallet-index-js": () => import("./../../../src/pages/nerdwallet/index.js" /* webpackChunkName: "component---src-pages-nerdwallet-index-js" */),
  "component---src-pages-nerdwallet-smb-index-js": () => import("./../../../src/pages/nerdwallet-smb/index.js" /* webpackChunkName: "component---src-pages-nerdwallet-smb-index-js" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-rippling-index-js": () => import("./../../../src/pages/rippling/index.js" /* webpackChunkName: "component---src-pages-rippling-index-js" */),
  "component---src-pages-static-index-js": () => import("./../../../src/pages/static/index.js" /* webpackChunkName: "component---src-pages-static-index-js" */),
  "component---src-pages-tax-calculator-index-js": () => import("./../../../src/pages/tax-calculator/index.js" /* webpackChunkName: "component---src-pages-tax-calculator-index-js" */)
}

